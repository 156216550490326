import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Network } from '@capacitor/network';
import { EventsService } from '../services/events.service';
import { Storage } from '@capacitor/storage';
import { ApiService } from '../services/api.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {

  network: boolean = true;
  offlineMode: boolean = false;

  currentRoute: string;
  isHome: boolean = false;

  showPrivates: boolean = false;

  constructor(private apiService: ApiService, private location: Location, private router: Router, private events: EventsService) {
    Network.getStatus().then((status) => {
      this.network = status.connected;
    });
    Network.addListener('networkStatusChange', status => {
      this.network = status.connected;
    });
    this.events.getOfflineModeState().subscribe((val) => {
      this.offlineMode = val;
    });

    router.events.subscribe((val) => {
      if (location.path() != ''){
        this.currentRoute = location.path();
      }
      if (this.currentRoute.startsWith('/home')) {
        this.isHome = true;
      } else {
        this.isHome = false;
      }
    });
  }

  ngOnInit() {
    this.apiService.listConfigs().pipe(first()).subscribe(
      next => {
        let privateConfig = next.data.find(config => config.title == "showPrivate");
        if (privateConfig && privateConfig.value == 'true') {
          this.showPrivates = true;
        } else {
          this.showPrivates = false;
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  showInfosBox() {
    this.events.infosBox(true);
  }

  async goHome() {
    const selectedBrandId = await Storage.get({ key: `selected-brand-id` });
    if (!selectedBrandId.value) {
      await Storage.set({
        key: `selected-brand-id`,
        value: 'all-brands',
      });
      await Storage.set({
        key: `selected-brand-name`,
        value: 'all-brands',
      });
      this.router.navigateByUrl(`/home`);
    } else {
      this.router.navigateByUrl(`/home`);
    }
  }

}
