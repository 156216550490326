import { Component, Renderer2 } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Storage } from '@capacitor/storage';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { EventsService } from './services/events.service';
import { ApiService } from './services/api.service';
import { first } from 'rxjs/operators';
import { Network } from '@capacitor/network';
import { ToastController, Platform } from '@ionic/angular';
import { CachingService } from './services/caching.service';
import { detectIncognito } from "detectincognitojs";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  providers: [Keyboard]
})
export class AppComponent {

  currentRoute: string;
  offlineMode: boolean = false;
  showTabs: boolean = true;

  externalLinks: any = [];

  network: boolean = true;
  privateNav: boolean = false;

  isEmbed: boolean = false;

  showAppsLogo: boolean = false;
  showPrivates: boolean = false;

  constructor(private renderer: Renderer2, public platform: Platform, private cachingService: CachingService, private toastController: ToastController, private apiService: ApiService, public events: EventsService, private keyboard: Keyboard, private location: Location, private router: Router) {
    this.cachingService.initStorage();

    router.events.subscribe((val) => {
      if (location.path() != ''){
        this.currentRoute = location.path();
        if (this.currentRoute.startsWith('/sent/')) {
          if (this.currentRoute.includes('?app=true')) {
            this.showTabs = true;
          } else {
            this.showTabs = false;
          }
        }
        if (this.currentRoute.includes('?embed=true')) {
          this.isEmbed = true;
          this.renderer.addClass(document.querySelector('body'), 'is-embed');
        }
      }
    });
    window.addEventListener('keyboardWillShow', () => {
      this.showTabs = false;
    });
    window.addEventListener('keyboardWillHide', () => {
      if (!this.currentRoute.startsWith('/box')) {
        this.showTabs = true;
      }
    });

    this.events.getTabsState().subscribe((res:any)=>{
      if (res == 'hide') {
        this.showTabs = false;
      } else {
        this.showTabs = true;
      }
    });

    Network.getStatus().then((status) => {
      this.network = status.connected;
      this.events.setOfflineState(!this.network);
      this.offlineMode = !this.network;
    });
    Network.addListener('networkStatusChange', status => {
      this.network = status.connected;
      this.events.setOfflineState(!this.network);
      this.offlineMode = !this.network;
    });

    detectIncognito().then((result) => {
      if (result.isPrivate && !this.platform.is('hybrid')) {
        this.privateNav = true;
      } else {
        this.privateNav = false;
      }
    });

    if (!this.platform.is('hybrid')) {
      this.showAppsLogo = true;
    } else {
      this.showAppsLogo = false;
    }

  }

  async presentToast(message, customClass) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: 'bottom',
      cssClass: customClass
    });

    await toast.present();
  }

  async ngOnInit() {
    this.initExternalLinks();
    const offlineTriggered = await Storage.get({ key: `offline-mode-triggered` });
    if (offlineTriggered.value && offlineTriggered.value == 'true') {
      this.events.setOfflineState(true);
      this.offlineMode = true;
    }
  }

  initExternalLinks() {
    this.externalLinks = [];
    this.apiService.listConfigs().pipe(first()).subscribe(
      next => {
        let privateConfig = next.data.find(config => config.title == "showPrivate");
        if (privateConfig && privateConfig.value == 'true') {
          this.showPrivates = true;
        } else {
          this.showPrivates = false;
        }
        next.data.forEach(config => {
          let key = config.title.substring(5).split('-')[0];
          let type = config.title.substring(5).split('-')[1];
    
          let partner = next.data.find(x => (x.title.substring(5).split('-')[0] == key) && (x.title.substring(5).split('-')[1] !== type));
    
          if (partner) {
            if (type == 'title') {
              if (!this.externalLinks.find(x => x.title == config.value)) {
                this.externalLinks.push({title: config.value, url: partner.value});
              }
            } else {
              if (!this.externalLinks.find(x => x.title == partner.value)) {
                this.externalLinks.push({title: partner.value, url: config.value});
              }
            }
          }

        });
      },
      err => {
        console.log(err);
      }
    );
  }

  offlineModeChange(e) {
    this.offlineMode = e.currentTarget.checked;
    if (this.offlineMode) {
      Storage.set({
        key: `offline-mode-triggered`,
        value: 'true',
      });
      this.events.setOfflineState(true);
    } else {
      if (!this.network) {
        this.offlineMode = true;
        e.currentTarget.checked = true;
        this.presentToast('Vous devez être connecté à internet pour passer en mode en ligne', 'error-toast');
      } else {
        Storage.set({
          key: `offline-mode-triggered`,
          value: 'false',
        });
        this.events.setOfflineState(false);
      }
    }    
  }
  // public throwTestError(): void {
  //   throw new Error("Sentry Test Error");
  // }
}
