<ion-app>
  <!-- <button (click)="throwTestError()" style="z-index: 1000;">Test Sentry Error</button> -->
  <ion-menu contentId="main-content" *ngIf="!isEmbed">
    <div class="title" [class.offline]="!network || offlineMode">
      Menu rapide
    </div>
    <ul>
      <ion-menu-toggle>
        <a routerLink="/brands" routerLinkActive="active"> Les thèmes </a>
      </ion-menu-toggle>
      <ion-menu-toggle>
        <a routerLink="/home" routerLinkActive="active"> Les boîtes </a>
      </ion-menu-toggle>
      <ion-menu-toggle>
        <a routerLink="/files" routerLinkActive="active">
          Mes enregistrements
        </a>
      </ion-menu-toggle>
      <ion-menu-toggle *ngIf="network && !offlineMode && showPrivates">
        <a routerLink="/private" routerLinkActive="active"> Contenu privé </a>
      </ion-menu-toggle>
      <ion-menu-toggle *ngFor="let link of externalLinks">
        <a [href]="link.url" target="_blank">
          {{ link.title }}
        </a>
      </ion-menu-toggle>
    </ul>
    <div class="apps" *ngIf="showAppsLogo">
      <div class="ios">
        <a
          href="https://apps.apple.com/us/app/la-place-aux-questions/id6443809264"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ion-img src="../../assets/icon/logo_appleok.png"></ion-img>
        </a>
      </div>
      <div class="android">
        <a
          href="https://play.google.com/store/apps/details?id=place.questions"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ion-img src="../../assets/icon/logo_androidok.png"></ion-img>
        </a>
      </div>
    </div>
    <div class="offline-mode" [class.apps-above]="showAppsLogo">
      <div class="title">Utilisation hors-ligne</div>
      <div class="switch">
        <div class="toggle-button-cover">
          <div class="button-cover">
            <div class="button">
              <input
                type="checkbox"
                class="checkbox"
                [checked]="offlineMode || !network"
                (change)="offlineModeChange($event)"
              />
              <div class="knobs"></div>
              <div class="layer"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ion-menu>
  <div
    class="offline-notice"
    *ngIf="
      (!network || offlineMode) && currentRoute &&
      !currentRoute.startsWith('/box') &&
      !privateNav
    "
  >
    <ion-icon name="wifi-outline"></ion-icon>
    Vous êtes actuellement hors ligne, relancez votre connexion internet ou
    activez le mode "en ligne" pour afficher toutes les fonctionnalités
  </div>
  <div
    class="private-notice"
    *ngIf="privateNav && currentRoute && !currentRoute.startsWith('/box')"
  >
    Vous êtes en navigation privée. La Place aux questions nécessite une
    navigation "classique" afin de pouvoir profiter pleinement de toutes ses
    fonctionnalités. Nous vous invitons à changer votre type de navigation.
  </div>
  <ion-router-outlet id="main-content"></ion-router-outlet>
  <app-tabs
    *ngIf="
      currentRoute &&
      showTabs &&
      !isEmbed &&
      !currentRoute.startsWith('/explanations')
    "
  ></app-tabs>
</ion-app>
