import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

private state = new Subject<any>();

private offlineState = new BehaviorSubject<boolean>(false);
private offlineState$ = this.offlineState.asObservable();

private stateInfosBox = new Subject<any>();


constructor() { }

  tabsState(data: any) {
    this.state.next(data);
  }

  getTabsState(): Subject<any> {
    return this.state;
  }

  setOfflineState(data: any) {
    this.offlineState.next(data);
  }

  getOfflineModeState(): Observable<any> {
    return this.offlineState$;
  }

  infosBox(data: any) {
    this.stateInfosBox.next(data);
  }

  getInfosBox(): Subject<any> {
    return this.stateInfosBox;
  }

}