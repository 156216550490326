<div class="tabs">
  <div class="tab records" routerLink="/files" routerLinkActive="active">
    <div class="icone">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="60.151" height="61.196"
        viewBox="0 0 60.151 61.196">
        <defs>
          <linearGradient id="linear-gradient-tab-records" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
            <stop offset="0" stop-color="#0338ee" />
            <stop offset="0.126" stop-color="#2338ee" />
            <stop offset="0.441" stop-color="#6e38ef" />
            <stop offset="0.702" stop-color="#a538ef" />
            <stop offset="0.895" stop-color="#c838ef" />
            <stop offset="1" stop-color="#d538f0" />
          </linearGradient>
        </defs>
        <g id="Groupe_3458" data-name="Groupe 3458" transform="translate(-3148.566 -2061.259)">
          <circle id="Ellipse_202" data-name="Ellipse 202" cx="13.4" cy="13.4" r="13.4"
            transform="translate(3165.241 2061.259)" fill="url(#linear-gradient-tab-records)" />
          <path id="Tracé_1811" data-name="Tracé 1811" d="M3148.566,2122.454a30.076,30.076,0,1,1,60.151,0Z"
            fill="url(#linear-gradient-tab-records)" />
        </g>
      </svg>
    </div>
    <div class="title">
      Mes enregistrements
    </div>
  </div>
  <div class="tab places" (click)="goHome()" [class.active]="isHome">
    <div class="icone">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="26.801" height="26.801"
        viewBox="0 0 26.801 26.801">
        <defs>
          <linearGradient id="linear-gradient-tab-places" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
            <stop offset="0" stop-color="#0338ee" />
            <stop offset="0.126" stop-color="#2338ee" />
            <stop offset="0.441" stop-color="#6e38ef" />
            <stop offset="0.702" stop-color="#a538ef" />
            <stop offset="0.895" stop-color="#c838ef" />
            <stop offset="1" stop-color="#d538f0" />
          </linearGradient>
        </defs>
        <circle id="Ellipse_202" data-name="Ellipse 202" cx="13.4" cy="13.4" r="13.4"
          fill="url(#linear-gradient-tab-places)" />
      </svg>
    </div>
    <div class="title">
      Les boîtes
    </div>
  </div>
  <div class="tab privates" routerLink="/private" routerLinkActive="active"
    *ngIf="showPrivates && network && !this.offlineMode && (!currentRoute || (currentRoute && !currentRoute.startsWith('/box')))">
    <div class="icone">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="46.978" height="70.922"
        viewBox="0 0 46.978 70.922">
        <defs>
          <linearGradient id="linear-gradient-tab-private" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
            <stop offset="0" stop-color="#0338ee" />
            <stop offset="0.126" stop-color="#2338ee" />
            <stop offset="0.441" stop-color="#6e38ef" />
            <stop offset="0.702" stop-color="#a538ef" />
            <stop offset="0.895" stop-color="#c838ef" />
            <stop offset="1" stop-color="#d538f0" />
          </linearGradient>
        </defs>
        <path id="Tracé_1612" data-name="Tracé 1612"
          d="M3444.807,8713.9h-29.69v-11.773a12.092,12.092,0,0,1,24.184,0,2.5,2.5,0,0,0,5,0,17.092,17.092,0,0,0-34.184,0V8713.9h-.506a5.89,5.89,0,0,0-5.891,5.891v30.275a5.891,5.891,0,0,0,5.891,5.891h35.2a5.891,5.891,0,0,0,5.891-5.891v-30.275A5.89,5.89,0,0,0,3444.807,8713.9Z"
          transform="translate(-3403.72 -8685.033)" fill="url(#linear-gradient-tab-private)" />
      </svg>
    </div>
    <div class="title">
      Contenu privé
    </div>
  </div>
  <!-- <div class="tab info-box" *ngIf="currentRoute && currentRoute.startsWith('/box')" (click)="showInfosBox()">
    <div class="icone">
      <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
        <path d="M256 80a176 176 0 10176 176A176 176 0 00256 80z" fill="none" stroke="#BCBAC6" stroke-miterlimit="10"
          stroke-width="32" />
        <path
          d="M200 202.29s.84-17.5 19.57-32.57C230.68 160.77 244 158.18 256 158c10.93-.14 20.69 1.67 26.53 4.45 10 4.76 29.47 16.38 29.47 41.09 0 26-17 37.81-36.37 50.8S251 281.43 251 296"
          fill="none" stroke="#DEDEDE" stroke-linecap="round" stroke-miterlimit="10" stroke-width="28" />
        <circle cx="250" cy="348" r="20" fill="#DEDEDE" stroke="#DEDEDE" />
      </svg>
    </div>
    <div class="title">
      Plus d'informations
    </div>
  </div> -->
  <div class="tab info-box" *ngIf="currentRoute && currentRoute.startsWith('/box')" (click)="showInfosBox()">
    <div class="icone">
      <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
        <path d="M256 80a176 176 0 10176 176A176 176 0 00256 80z" fill="none" stroke="#BCBAC6" stroke-miterlimit="10"
          stroke-width="32" />
        <path
          d="M200 202.29s.84-17.5 19.57-32.57C230.68 160.77 244 158.18 256 158c10.93-.14 20.69 1.67 26.53 4.45 10 4.76 29.47 16.38 29.47 41.09 0 26-17 37.81-36.37 50.8S251 281.43 251 296"
          fill="none" stroke="#DEDEDE" stroke-linecap="round" stroke-miterlimit="10" stroke-width="28" />
        <circle cx="250" cy="348" r="20" fill="#DEDEDE" stroke="#DEDEDE" />
      </svg>
    </div>
    <div class="title">
      Aide
    </div>
  </div>
</div>