import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { from, throwError, BehaviorSubject, Observable } from 'rxjs';
import { share, map, tap, catchError } from 'rxjs/operators';
import { CachingService } from './caching.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  baseApiUrl: string = environment.baseApiUrl;
  apiAuth: string = environment.apiAuth;
  apiPass: string = environment.apiPass;

  httpFormOptions = {
    headers: new HttpHeaders({
      Authorization: 'Basic ' + btoa(this.apiAuth + ':' + this.apiPass)
    })
  };

  constructor(private cachingService: CachingService, private http: HttpClient) { }

  uploadFile(file: any) {
    const form: FormData = new FormData();
    form.set('file', file);

    let encodeApiFormOptions: any = {
      headers: new HttpHeaders({
        Authorization: 'Basic ' + btoa('fk9c2jgXvz:9cjXjReWgp')
      }),
      reportProgress: true,
      observe: "events"
    };

    return this.http.post<HttpEvent<any>>(
      `https://encode.jolifish.eu/api/tasks/upload`,
      form,
      encodeApiFormOptions,
    )
      .pipe(
        catchError(errorRes => {
          const errorMessage = errorRes.error.message;
          throw new Error(errorMessage);
        })
      );
  }

  listBrands() {
    return this.http.get<any>(
      `${environment.baseApiUrl}brands/list`,
      this.httpFormOptions
    )
      .pipe(
        catchError(errorRes => {
          const errorMessage = errorRes.error.message;
          throw new Error(errorMessage);
        }),
        tap(resData => {
          resData.data = Object.keys(resData.data).map(function (key) {
            return resData.data[key];
          });
          resData.data = resData.data.filter(function (brand: { nbrBoxes: number; }) {
            return brand.nbrBoxes !== 0;
          });
          this.cachingService.cacheRequest(`${environment.baseApiUrl}brands/list`, resData);
          return resData.data;
        })
      );
  }

  listCategories() {
    return this.http.get<any>(
      `${environment.baseApiUrl}categories/list`,
      this.httpFormOptions
    )
      .pipe(
        catchError(errorRes => {
          const errorMessage = errorRes.error.message;
          throw new Error(errorMessage);
        }),
        tap(resData => {
          resData.data = resData.data.filter(function (cat: { boxes: string | any[]; }) {
            return cat.boxes.length > 0;
          });
          this.cachingService.cacheRequest(`${environment.baseApiUrl}categories/list`, resData);
          return resData.data;
        })
      );
  }

  listBoxes(options?: { brand?: any, category?: any }) {
    if (options) {
      if (options.brand) {
        return this.http.get<any>(
          `${environment.baseApiUrl}boxes/list?brand_ids=${options.brand}`,
          this.httpFormOptions
        )
          .pipe(
            catchError(errorRes => {
              const errorMessage = errorRes.error.message;
              throw new Error(errorMessage);
            }),
            tap(resData => {
              this.cachingService.cacheRequest(`${environment.baseApiUrl}boxes/list?brand_ids=${options.brand}`, resData);
              return resData.data;
            })
          );
      }
      if (options.category) {
        return this.http.get<any>(
          `${environment.baseApiUrl}boxes/list?category_ids=${options.category}`,
          this.httpFormOptions
        )
          .pipe(
            catchError(errorRes => {
              const errorMessage = errorRes.error.message;
              throw new Error(errorMessage);
            }),
            tap(resData => {
              this.cachingService.cacheRequest(`${environment.baseApiUrl}boxes/list?category_ids=${options.category}`, resData);
              return resData.data;
            })
          );
      }
    } else {
      return this.http.get<any>(
        `${environment.baseApiUrl}boxes/list`,
        this.httpFormOptions
      )
        .pipe(
          catchError(errorRes => {
            const errorMessage = errorRes.error.message;
            throw new Error(errorMessage);
          }),
          tap(resData => {
            this.cachingService.cacheRequest(`${environment.baseApiUrl}boxes/list`, resData);
            return resData.data;
          })
        );
    }
  }

  getBox(boxes_ids: number) {
    return this.http.get<any>(
      `${environment.baseApiUrl}boxes/get?boxes_ids=${boxes_ids}`,
      this.httpFormOptions
    )
      .pipe(
        catchError(errorRes => {
          const errorMessage = errorRes.error.message;
          throw new Error(errorMessage);
        })
      );
  }

  sendPassword(box_id: number, password: string) {
    return this.http.get<any>(
      `${environment.baseApiUrl}boxes/password?box_id=${box_id}&password=${password}`,
      this.httpFormOptions
    )
      .pipe(
        catchError(errorRes => {
          const errorMessage = errorRes.error.message;
          throw new Error(errorMessage);
        })
      );
  }

  sendBox(video_url: string, box_id: any, api_url: string, api_value: string, options?: {
    email?: any,
    form_response_id?: any
  }, present_id?: any) {

    let params = `origin_video_url=${video_url}`
      + `&api_url=${api_url}`
      + `&box_id=${box_id}`
      + `&api_value=${api_value}`;

    if (options) {
      if (options.email) params += `&email=${options.email}`;
      if (options.form_response_id) params += `&form_response_id=${options.form_response_id}`;
    }

    if (present_id) params += `&present_id=${present_id}`;

    return this.http.get<any>(
      `${environment.baseApiUrl}boxes/send?${params}`,
      this.httpFormOptions
    )
      .pipe(
        catchError(errorRes => {
          const errorMessage = errorRes.error.message;
          throw new Error(errorMessage);
        })
      );
  }

  getSentBox(sent_id: any) {
    return this.http.get<any>(
      `${environment.baseApiUrl}sent/get?sent_id=${sent_id}`,
      this.httpFormOptions
    )
      .pipe(
        catchError(errorRes => {
          const errorMessage = errorRes.error.message;
          throw new Error(errorMessage);
        })
      );
  }

  sendForm(box_id: any, form_id: any, value: string) {
    return this.http.get<any>(
      `${environment.baseApiUrl}forms/send?box_id=${box_id}&form_id=${form_id}&value=${value}`,
      this.httpFormOptions
    )
      .pipe(
        catchError(errorRes => {
          const errorMessage = errorRes.error.message;
          throw new Error(errorMessage);
        })
      );
  }

  getFormResponse(form_responses_id: any) {
    return this.http.get<any>(
      `${environment.baseApiUrl}form_responses/get?form_responses_id=${form_responses_id}`,
      this.httpFormOptions
    )
      .pipe(
        catchError(errorRes => {
          const errorMessage = errorRes.error.message;
          throw new Error(errorMessage);
        })
      );
  }

  getPrivateBox(private_code: string) {
    return this.http.get<any>(
      `${environment.baseApiUrl}boxes/private?private_code=${private_code}`,
      this.httpFormOptions
    )
      .pipe(
        catchError(errorRes => {
          const errorMessage = errorRes.error.message;
          throw new Error(errorMessage);
        })
      );
  }

  listConfigs() {
    return this.http.get<any>(
      `${environment.baseApiUrl}configs/list`,
      this.httpFormOptions
    )
      .pipe(
        catchError(errorRes => {
          const errorMessage = errorRes.error.message;
          throw new Error(errorMessage);
        })
      );
  }

  acceptShare(sent_id: any) {
    return this.http.get<any>(
      `${environment.baseApiUrl}sent/share?sent_id=${sent_id}`,
      this.httpFormOptions
    )
      .pipe(
        catchError(errorRes => {
          const errorMessage = errorRes.error.message;
          throw new Error(errorMessage);
        })
      );
  }

  getDownloadLink(vimeo_id: any) {
    const form: FormData = new FormData();
    form.set('vimeo_id', vimeo_id);

    let encodeApiFormOptions: any = {
      headers: new HttpHeaders({
        Authorization: 'Basic ' + btoa('fk9c2jgXvz:9cjXjReWgp')
      })
    };

    return this.http.post<HttpEvent<any>>(
      `https://encode.jolifish.eu/api/tasks/get_video_download_link`,
      form,
      encodeApiFormOptions,
    )
      .pipe(
        catchError(errorRes => {
          const errorMessage = errorRes.error.message;
          throw new Error(errorMessage);
        })
      );
  }

  sendProblem(firstname: any, name: any, email: any, description: any, infos: any) {
    return this.http.get<any>(
      `${environment.baseApiUrl}problem/send?firstname=${firstname}&name=${name}&email=${email}&description=${description}&infos=${infos}`,
      this.httpFormOptions
    )
      .pipe(
        catchError(errorRes => {
          const errorMessage = errorRes.error.message;
          throw new Error(errorMessage);
        })
      );
  }

  createPreSent(box_id: any, email: any) {
    // create a POST call to the api sent/present
    const form: FormData = new FormData();
    form.set('box_id', box_id);
    form.set('email', email);

    let encodeApiFormOptions: any = {
      headers: new HttpHeaders({
        Authorization: 'Basic ' + btoa('fk9c2jgXvz:9cjXjReWgp')
      })
    };

    return this.http.post<HttpEvent<any>>(
      `${environment.baseApiUrl}sent/present`,
      form,
      encodeApiFormOptions,
    )
      .pipe(
        catchError(errorRes => {
          const errorMessage = errorRes.error.message;
          throw new Error(errorMessage);
        })
      );
  }
}